@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  li {
    @apply p-4
  }

  button {
    @apply text-white border bg-blue-600 border-blue-600
    hover:bg-transparent hover:text-blue-600 rounded-md
  }
}

@layer components {
  .gradient-text {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-green-600 to-blue-600;
  }
}

/* Custom Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-in-out;
}

.animate-blink {
  animation: blink 2s ease-in-out infinite;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #F3F4F6;
}

::-webkit-scrollbar-thumb {
  background: #D1D5DB;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9CA3AF;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #D1D5DB #F3F4F6;
}

/* Section Transitions */
section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

section.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Hover Effects */
.hover-scale {
  transition: transform 0.2s ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.05);
}

/* Custom Colors for Fijoli Text */
.text-fi {
  color: #4ade80; /* Green */
}

.text-jo {
  color: #3b82f6; /* Blue */
}

.text-li {
  color: #f59e0b; /* Orange */
}